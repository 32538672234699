//#region Imports
//Ionic components
import { IonContent, IonHeader, IonButton, IonPage, useIonAlert, IonSpinner } from "@ionic/react"
import { menuController } from '@ionic/core/components';
//Use React
import { useState, useEffect } from "react";
import { useParams, useHistory } from 'react-router-dom';
//Components
import NavBar from "../components/NavBar";
import MenuBar from "../components/MenuBar";
//Axios
import axios from 'axios'
//JS Cookies
import Cookies from 'js-cookie';
//MultiLanguage
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { t } from 'i18next';
// Style
import './form.css'
//#endregion



const Verify = () => {

  //#region Variables
  //Routes
  const history = useHistory()
  const {token} = useParams();
  //Server IP Address
  const serverIP = "recibits.com"
  //Process checker
  const [verifying, setVerifying] = useState(false)
  //Alert
  const [verifyAlert] = useIonAlert()
  //Language Data
  const [lang, setLang] = useState("en")
  const resources = {
    en: {
        translation: {
            "Verify your account": "Verify your account"
        },
    },
    ar: {
        translation: {
            "Verify your account": "التحقق من حسابك",
        },
    },
  };
  i18n.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem('language') || lang, // Default language
    keySeparator: false,
    interpolation: { escapeValue: false },
  });
  //#endregion
  
  //#region Functions
  //Language Set From Cookies
  const onLangClick = () => {
    if(lang == "en"){
      setLang("ar")
      Cookies.set('language', "ar", { expires: 365, path: '/' });
    }
    else{
      setLang("en")
      Cookies.set('language', "en", { expires: 365, path: '/' });
    } 
  }
  //Verify Email Process
  const verifyEmail = async (token) => {
    setVerifying(true)
    await axios.put(`https://${serverIP}/api/updateVerify`,{token: token}, {withCredentials: true})
      .then(async (result) => {
        if(result.data == 'success'){
          verifyAlert({
            header: lang=='en'?'Done!':"!انتهى",
            message: lang=='en'?'Your account has been verified!':'لقد تم التحقق من حسابك!',
            buttons: [lang=='en'?"Ok":"نعم"],
            cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
            mode: 'md',
          })
          history.replace('/')
        }else if(result.data == 'invalid'){
          verifyAlert({
            header: lang=='en'?"ُError!":"!خطأ",
            message: lang=='en'?'Invalid verification link!':'رابط التحقق غير صالح!',
            buttons: [lang=='en'?"Ok":"نعم"],
            cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
            mode: 'md',
          })
        }else{
          verifyAlert({
            header: lang=='en'?"ُError!":"!خطأ",
            message: lang=='en'?'An error occured! Please try again.':'حدث خطأ! يرجى المحاولة مرة أخرى.',
            buttons: [lang=='en'?"Ok":"نعم"],
            cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
            mode: 'md',
          })
        }
    }).catch(async error => {
      if (error.response?.status === 403 && error.response?.data?.error === 'Invalid CSRF token') {
        const trial = await fetchCsrfToken(); // Refresh the token
        if(!trial){
          verifyAlert({
            header: lang=='en'?"ُError!":"!خطأ",
            message: lang=='en'?"Can't connect to the server! Please refresh.":'لا يمكن الاتصال بالخادم! يرجى تحديث الموقع.',
            buttons: [lang=='en'?"Ok":"نعم"],
            cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
            mode: 'md',
          })  
        }else{
          // Retry Reset Password
          verifyEmail(token);
        }
      }else{
        verifyAlert({
          header: lang=='en'?"ُError!":"!خطأ",
          message: lang=='en'?'An error occured! Please try again.':'حدث خطأ! يرجى المحاولة مرة أخرى.',
          buttons: [lang=='en'?"Ok":"نعم"],
          cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
          mode: 'md',
        })
      }
    })
    setVerifying(false)
  }
  //CSRF server Request
  const fetchCsrfToken = async () => {
    try {
      await axios.get(`https://${serverIP}/api/csrf-token`,{withCredentials: true}).then(()=>{
        return 1
      });
    } catch (error) {
      //Error Handling
      return 0
    }
  };
  //#endregion

  //#region Use Effect
  useEffect(()=> {
    //Access Token
    const intervalId = setInterval(() => {
        // Call the refresh token endpoint to renew the access token
        axios.post(`https://${serverIP}/api/refresh-authenticate`,{},{withCredentials: true })
          .then(response => {
            // console.log('Access token refreshed');
          })
          .catch(error => {
            // console.error('Error refreshing token:', error);
          });
      }, 55 * 60 * 1000);  // Refresh the token every 55 minutes (before expiry)
        
    //Access Token Refresh
    const authenticateAPI = async () => {  
      await axios.post(`https://${serverIP}/api/authenticate`,{}, { withCredentials: true })
      .then(response => {
        //Response
      })
      .catch(error => {
        //Error handling
      });
    }

    const loadData = async () => {
      setLang(Cookies.get('language') || 'en')
      await fetchCsrfToken()
      await authenticateAPI()
    }
    
    loadData()
  
    return () => {
        clearInterval(intervalId); 
    };
  },[])
  //#endregion

  return(

    <IonPage id="main-content">
      <IonHeader className='home_header'>
          <NavBar  lang={lang} onLangClick={onLangClick}></NavBar>
      </IonHeader>
      <div className='form_background'></div>
      <div className='form_background_img'></div>
      <IonContent align="center" fullscreen>
        <div className="form_verify">
          {!verifying ? 
            <IonButton 
              className="verify_btn" 
              onClick={() => verifyEmail(token)} 
            >
            {t('Verify your account')}
            </IonButton>
          :
          <IonButton mode="md" className="verify_btn"><IonSpinner name='circular' className='form_spinner'/></IonButton>
          }
        </div>

      </IonContent>
    </IonPage>

  )
}







export default Verify;