//#region Imports
//Import Ionic Components
import { IonContent, IonHeader, IonPage } from "@ionic/react"
import { menuController } from '@ionic/core/components';
//Import Components
import NavBar from "../components/NavBar";
import Footer from '../components/Footer';
import MenuBar from "../components/MenuBar";
//Import React Components
import {useState, useEffect} from 'react';
//Import Style
import './Privacy.css';
//Import JS Cookies
import Cookies from 'js-cookie';
//MultiLanguage
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { t } from 'i18next';
//#endregion

const Privacy = () => {

  //#region Variables
  //Language Data
  const [lang, setLang] = useState("en")
  const resources = {
      en: {
          translation: {
              "Privacy Policy": "Privacy Policy",
              "1. Introduction:": "1. Introduction:",
              "We at": "We at Recibits are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website Recibits.co.",
              "2. Data Collection and Usage": "2. Data Collection and Usage",
              "We collect": "We collect personal data that you voluntarily provide to us when you create an account, make a purchase, or contact us. The personal data we collect includes:",
              "Name": "Name",
              "Email address": "Email address",
              "Purposes of Data Collection:": "Purposes of Data Collection:",
              "To create and manage your account": "To create and manage your account",
              "For email marketing purposes": "For email marketing purposes",
              "Cookies": "Cookies: We use cookies to save personal IDs to retrieve account favorites and premium information.",
              "3. Data Sharing and Security": "3. Data Sharing and Security",
              "Personal": "Personal data is saved in our database and never shared with third parties.",
              "Data": "Data is safely saved and encrypted in the database to protect against any breach of accounts.",
              "4. User Rights": "4. User Rights",
              "Users": "Users have the ability to change their data and request the deletion of their accounts. Users might be contacted via email for marketing purposes.",
              "Under GDPR:": "Under GDPR:",
              "Right to Access": "Right to Access: You have the right to request access to your personal data and obtain a copy of it.",
              "Right to Rect": "Right to Rectification: You have the right to request correction of inaccurate or incomplete personal data.",
              "Right to Erasure": "Right to Erasure: You have the right to request the deletion of your personal data under certain conditions.",
              "Right to Restrict": "Right to Restrict Processing: You have the right to request the restriction of processing of your personal data under certain conditions.",
              "Right to Data": "Right to Data Portability: You have the right to request the transfer of your personal data to another organization or directly to you under certain conditions.",
              "Right to Object": "Right to Object: You have the right to object to the processing of your personal data under certain conditions.",
              "Under CCPA:": "Under CCPA:",
              "Right to Know": "Right to Know: You have the right to know the categories of personal data we collect, the purposes for which we use it, and the categories of third parties with whom we share it.",
              "Right to Access2": "Right to Access: You have the right to request access to the specific pieces of personal data we have collected about you.",
              "Right to Delete": "Right to Deletion: You have the right to request the deletion of your personal data, subject to certain exceptions.",
              "Right to Opt": "Right to Opt-Out: You have the right to opt-out of the sale of your personal data. (Note: We do not sell personal data.)",
              "Right to Non": "Right to Non-Discrimination: You have the right not to be discriminated against for exercising your CCPA rights.",
              "To exercise": "To exercise any of these rights, please contact us at recibits@gmail.com.",
              "5. Changes to This Privacy Policy": "5. Changes to This Privacy Policy",
              "We may update": "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.",
              "6. Contact Us": "6. Contact Us",
              "If": "If you have any questions about this Privacy Policy, please contact us at recibits@gmail.com."

          },
      },
      ar: {
          translation: {
              "Privacy Policy": "سياسة الخصوصية",
              "1. Introduction:": "1. المقدمة",
              "We at": "نحن في Recibits ملتزمون بحماية خصوصيتك. تشرح سياسة الخصوصية هذه كيف نجمع معلوماتك ونستخدمها ونكشف عنها ونحميها عندما تزور موقعنا الإلكتروني",
              "2. Data Collection and Usage": "2. جمع البيانات واستخدامها",
              "We collect": "نقوم بجمع البيانات الشخصية التي تقدمها لنا طوعًا عند إنشاء حساب أو إجراء عملية شراء أو الاتصال بنا. تشمل البيانات الشخصية التي نجمعها ما يلي:",
              "Name": "الاسم",
              "Email address": "البريد الالكتروني",
              "Purposes of Data Collection:": "أغراض جمع البيانات:",
              "To create and manage your account": "لإنشاء وإدارة حسابك",
              "For email marketing purposes": "لأغراض التسويق عبر البريد الإلكتروني",
              "Cookies": "ملفات تعريف الارتباط: نستخدم ملفات تعريف الارتباط لحفظ المعرفات الشخصية لاسترداد مفضلات الحساب والمعلومات المميزة",
              "3. Data Sharing and Security": "3. مشاركة البيانات والأمن",
              "Personal": "يتم حفظ البيانات الشخصية في قاعدة البيانات الخاصة بنا ولا يتم مشاركتها أبدًا مع أطراف ثالثة.",
              "Data": "يتم حفظ البيانات وتشفيرها بشكل آمن في قاعدة البيانات للحماية من أي اختراق للحسابات.",
              "4. User Rights": "4. حقوق المستخدم ",
              "Users": "يتمتع المستخدمون بالقدرة على تغيير بياناتهم وطلب حذف حساباتهم. قد يتم الاتصال بالمستخدمين عبر البريد الإلكتروني لأغراض التسويق.",
              "Under GDPR:": "بموجب قانون GDPR:",
              "Right to Access": "حق الوصول: يحق لك طلب الوصول إلى بياناتك الشخصية والحصول على نسخة منها.",
              "Right to Rect": "الحق في التصحيح: لديك الحق في طلب تصحيح البيانات الشخصية غير الدقيقة أو غير الكاملة.",
              "Right to Erasure": "الحق في المحو: يحق لك طلب حذف بياناتك الشخصية بموجب شروط معينة.",
              "Right to Restrict": "الحق في تقييد المعالجة: يحق لك طلب تقييد معالجة بياناتك الشخصية بموجب شروط معينة.",
              "Right to Data": "الحق في إمكانية نقل البيانات: يحق لك طلب نقل بياناتك الشخصية إلى مؤسسة أخرى أو إليك مباشرةً بموجب شروط معينة.",
              "Right to Object": "الحق في الاعتراض: لديك الحق في الاعتراض على معالجة بياناتك الشخصية في ظل ظروف معينة.",
              "Under CCPA:": "بموجب قانون CCPA:",
              "Right to Know": "الحق في المعرفة: لديك الحق في معرفة فئات البيانات الشخصية التي نجمعها، والأغراض التي نستخدمها من أجلها، وفئات الأطراف الثالثة التي نشاركها معها.",
              "Right to Access2": "حق الوصول: لديك الحق في طلب الوصول إلى أجزاء محددة من البيانات الشخصية التي جمعناها عنك.",
              "Right to Delete": "الحق في الحذف: لديك الحق في طلب حذف بياناتك الشخصية، مع مراعاة بعض الاستثناءات.",
              "Right to Opt": "الحق في إلغاء الاشتراك: لديك الحق في إلغاء الاشتراك في بيع بياناتك الشخصية. (ملاحظة: نحن لا نبيع البيانات الشخصية.)",
              "Right to Non": "الحق في عدم التمييز: لديك الحق في عدم التعرض للتمييز بسبب ممارسة حقوقك بموجب قانون CCPA.",
              "To exercise": "لممارسة أي من هذه الحقوق، يرجى الاتصال بنا على recibits@gmail.com.",
              "5. Changes to This Privacy Policy": "5. التغييرات على سياسة الخصوصية هذه",
              "We may update": "قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من وقت لآخر. وسوف نقوم بإعلامك بأي تغييرات عن طريق نشر سياسة الخصوصية الجديدة على هذه الصفحة. ننصحك بمراجعة سياسة الخصوصية هذه بشكل دوري لمعرفة أي تغييرات.",
              "6. Contact Us": "6. اتصل بنا",
              "If": "إذا كان لديك أي أسئلة حول سياسة الخصوصية هذه، يرجى الاتصال بنا على recibits@gmail.com."
    },
  },
  };
  i18n.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem('language') || lang, // Default language
    keySeparator: false,
    interpolation: { escapeValue: false },
  });
  //#endregion

  //#region Functions
  const onLangClick = () => {
    if(lang == "en"){
      setLang("ar")
      Cookies.set('language', "ar", { expires: 365, path: '/' });
    }
    else{
      setLang("en")
      Cookies.set('language', "en", { expires: 365, path: '/' });
    } 
  }
  //#endregion

  //#region Use Effect
  useEffect(()=>{
    setLang(Cookies.get('language') || 'en')
  },[])
  //#endregion
    
return(

    <IonPage id="main-content">
    <IonHeader class='privacy_header'>
        <NavBar  lang={lang} onLangClick={onLangClick}></NavBar>
    </IonHeader>
    <div className='privacy_background'></div>
    <IonContent className="privacy_content">
      <div className='privacy_background_img'></div>
      <div className="privacy_story" style={lang=='en'?{direction: "ltr", textAlign: "left",marginLeft:"10px"}:{direction: "rtl", textAlign: "right",marginRight:"10px"}}>
          <h2 className="privacy_main_title">{t('Privacy Policy')}</h2> 
          <h4 className="privacy_title">{t('1. Introduction:')}</h4>
          <p>{t('We at')}</p>
          <h4 className="privacy_title">{t('2. Data Collection and Usage')}</h4>
          <p>{t('We collect')}</p>
              <ul>
                  <li>{t('Name')}</li>
                  <li>{t('Email address')}</li>
              </ul>
              <p>{t('Purposes of Data Collection:')}</p>	
              <ul>
                  <li>{t('To create and manage your account')}</li>
                  <li>{t('For email marketing purposes')}</li>
              </ul>
              <p>{t('Cookies')}</p>
          <h4 className="privacy_title">{t('3. Data Sharing and Security')}</h4>
          <ul>
              <li>{t('Personal')}</li>
              <li>{t('Data')}</li>
          </ul>
          <h4 className="privacy_title">{t('4. User Rights')}</h4> 
          <p>{t('Users')}</p>
          <p>{t('Under GDPR:')}</p>
          <ul>
              <li>{t('Right to Access')}</li>
              <li>{t('Right to Rect')}</li>
              <li>{t('Right to Erasure')}</li>
              <li>{t('Right to Restrict')}</li>
              <li>{t('Right to Data')}</li>
              <li>{t('Right to Object')}</li>
          </ul>
          <p>{t('Under CCPA:')}</p>
          <ul>
              <li>{t('Right to Know')}</li>
              <li>{t('Right to Access2')}</li>
              <li>{t('Right to Delete')}</li>
              <li>{t('Right to Opt')}</li>
              <li>{t('Right to Non')}</li>
          </ul>
          <p>{t('To exercise')}</p>
          <h4 className="privacy_title">{t('5. Changes to This Privacy Policy')}</h4>
          <p>{t('We may update')}</p>
          <h4 className="privacy_title">{t('6. Contact Us')}</h4>
          <p>{t('If')}</p>
      </div>   
      <Footer lang={lang}></Footer>
    </IonContent>
    </IonPage>

)

}

export default Privacy;