//#region Imports
//Import Ionic Components
import { IonContent, IonHeader, IonInput, IonItem, IonPage } from "@ionic/react"
import { menuController } from '@ionic/core/components';
//Import Components
import NavBar from "../components/NavBar";
import Footer from '../components/Footer';
import MenuBar from "../components/MenuBar";
//Import React Components
import {useState, useEffect} from 'react';
//Import Style
import './Privacy.css';
//Import JS Cookies
import Cookies from 'js-cookie';
//MultiLanguage
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { t } from 'i18next';
//#endregion


const Terms = () => {

    //#region Variables
    //Language Data
    const [lang, setLang] = useState("en")
    const resources = {
        en: {
            translation: {
                "Terms and Conditions": "Terms and Conditions",
                "1. Introduction:": "1. Introduction:",
                "Welcome": "Welcome to Recibits. These Terms and Conditions govern your use of our website Recibits.co and services. By accessing or using our website, you agree to be bound by these Terms.",
                "2. Eligibility": "2. Eligibility",
                "Users must be" :"Users must be at least 13 years old to create an account.",
                "By creating": "By creating an account, users represent and warrant that they meet the eligibility requirements.",
                "3. Account Information": "3. Account Information",
                "Users must provide": "Users must provide accurate and complete information during the registration process.",
                "Users are responsible": "Users are responsible for updating their account information to keep it current and accurate.",
                "4. Account Security": "4. Account Security",
                "Users are responsible for": "Users are responsible for maintaining the confidentiality of their account credentials, including their password.",
                "Users must notify": "Users must notify us immediately of any unauthorized use of their account or any other breach of security.",
                "Users must use": "Users must use a strong password that includes a combination of letters, numbers, and special characters.",
                "5. Account Usage": "5. Account Usage",
                "Users must not": "Users must not share their account with others or allow others to access their account.",
                "Users are solely": "Users are solely responsible for all activities that occur under their account.",
                "Users must not use": "Users must not use their account for any illegal or unauthorized purpose.",
                "6. Account Termination": "6. Account Termination",
                "We reserve": "We reserve the right to suspend or terminate accounts that violate these Terms and Conditions, are inactive for an extended period, or are found to be involved in fraudulent or illegal activities.",
                "User may": "Users may terminate their account at any time by contacting us.",
                "7. Multiple Accounts": "7. Multiple Accounts",
                "Users are": "Users are prohibited from creating multiple accounts for the purpose of circumventing account limitations or engaging in deceptive practices.",
                "8. Prohibited Activities": "8. Prohibited Activities",
                "Users engage": "Users must not engage in activities that harm or disrupt the website or service, including but not limited to hacking, distributing malware, or spamming.",
                "Users must respect": "Users must respect the intellectual property rights of others and must not upload, post, or distribute content that infringes on these rights.",
                "9. Notifications and Communication": "9. Notifications and Communication",
                "By creating an": "By creating an account, users agree to receive communications from us, including but not limited to account-related notifications, updates, and promotional messages.",
                "Users manage": "Users can manage their communication preferences through their account settings or by contacting us.",
                "10. Compliance with Laws": "10. Compliance with Laws",
                "Users must comply": "Users must comply with all applicable laws and regulations when using their account and accessing our services.",
                "11. User Contributions": "11. User Contributions",
                "Users should": "Users should not violate any guidelines when sending opinions or feedback.",
                "Users not use": "Users must not use any content on the website for commercial purposes without permission.",
                "12. Dispute Resolution": "12. Dispute Resolution",
                "Governing": "Governing Law: These Terms and Conditions and any disputes arising out of or relating to them will be governed by and construed in accordance with the laws of Lebanon, without regard to its conflict of law principles.",
                "Jurisdiction": "Jurisdiction: You agree that any legal action or proceeding arising out of or relating to these Terms and Conditions or your use of our website/service shall be brought exclusively in the courts of Lebanon. You consent to the jurisdiction of and venue in such courts and waive any objection to such jurisdiction or venue.",
                "Informal": "Informal Resolution: Before initiating arbitration, you agree to attempt to resolve any dispute informally by contacting us at recibits@gmail.com. We will attempt to resolve the dispute informally by contacting you through email or other means. If a dispute is not resolved within 30 days of submission, either party may begin arbitration proceedings.",
                "13. Compliance with GDPR and CCPA": "13. Compliance with GDPR and CCPA",
                "GDPR": "GDPR Compliance: If you are a resident of the European Economic Area (EEA), you have certain data protection rights under the General Data Protection Regulation (GDPR). We are committed to protecting your personal data and ensuring its fair and transparent processing. To exercise any of these rights, please contact us at recibits@gmail.com.",
                "CCPA": "CCPA Compliance: If you are a resident of California, you have certain rights under the California Consumer Privacy Act (CCPA). We are committed to protecting your personal data and ensuring its fair and transparent processing. To exercise any of these rights, please contact us at recibits@gmail.com.",
                "14. Changes to These Terms": "14. Changes to These Terms",
                "We update terms": "We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on this page. You are advised to review these Terms periodically for any changes.",
                "15. Contact US": "15. Contact US",
                "If questions": "If you have any questions about these Terms, please contact us at recibits@gmail.com."

            },
        },
        ar: {
            translation: {
                "Terms and Conditions": "الأحكام والشروط",
                "1. Introduction:": "1. مقدمة:",
                "Welcome": "مرحبا بكم في Recibits. تحكم هذه الشروط والأحكام استخدامك لموقعنا وخدماتنا Recibits.co. من خلال الوصول إلى موقعنا أو استخدامه، فإنك توافق على الالتزام بهذه الشروط.",
                "2. Eligibility": "2. جدارة - أهلية",
                "Users must be": "يجب أن يكون عمر المستخدمين 13 عامًا على الأقل لإنشاء حساب.",
                "By creating": "من خلال إنشاء حساب، يقر المستخدمون ويضمنون أنهم يستوفون متطلبات الأهلية.",
                "3. Account Information": "3. معلومات الحساب",
                "Users must provide": "يجب على المستخدمين تقديم معلومات دقيقة وكاملة أثناء عملية التسجيل.",
                "Users are responsible": "يتحمل المستخدمون مسؤولية تحديث معلومات حساباتهم لإبقائها حديثة ودقيقة.",
                "4. Account Security": " 4. أمن الحساب",
                "Users are responsible for": "يتحمل المستخدمون مسؤولية الحفاظ على سرية بيانات اعتماد حساباتهم، بما في ذلك كلمة المرور الخاصة بهم.",
                "Users must notify": "يجب على المستخدمين إخطارنا على الفور بأي استخدام غير مصرح به لحسابهم أو أي خرق آخر للأمن.",
                "Users must use": "يجب على المستخدمين استخدام كلمة مرور قوية تتضمن مجموعة من الأحرف والأرقام والأحرف الخاصة.",
                "5. Account Usage": " 5. استخدام الحساب",
                "Users must not": "يجب على المستخدمين عدم مشاركة حساباتهم مع الآخرين أو السماح للآخرين بالوصول إلى حساباتهم.",
                "Users are solely": "يتحمل المستخدمون وحدهم المسؤولية عن جميع الأنشطة التي تحدث ضمن حساباتهم.",
                "Users must not use": "يجب على المستخدمين عدم استخدام حساباتهم لأي غرض غير قانوني أو غير مصرح به.",
                "6. Account Termination": "6. إنهاء الحساب",
                "We reserve": "نحن نحتفظ بالحق في تعليق أو إنهاء الحسابات التي تنتهك هذه الشروط والأحكام، أو غير النشطة لفترة طويلة، أو التي يتبين أنها متورطة في أنشطة احتيالية أو غير قانونية.",
                "Users may": "يمكن للمستخدمين إنهاء حساباتهم في أي وقت عن طريق الاتصال بنا.",
                "7. Multiple Accounts": " 7. حسابات متعددة ",
                "Users are": "يُحظر على المستخدمين إنشاء حسابات متعددة بغرض التحايل على قيود الحساب أو الانخراط في ممارسات خادعة.",
                "8. Prohibited Activities": " 8. الأنشطة المحظورة",
                "Users engage": "يجب على المستخدمين عدم المشاركة في أنشطة تضر أو ​​تعطل الموقع أو الخدمة، بما في ذلك على سبيل المثال لا الحصر، القرصنة أو توزيع البرامج الضارة أو البريد العشوائي.",
                "Users must respect": "يجب على المستخدمين احترام حقوق الملكية الفكرية للآخرين ويجب ألا يقوموا بتحميل أو نشر أو توزيع محتوى ينتهك هذه الحقوق.",
                "9. Notifications and Communication": " 9. الإخطارات والاتصالات",
                "By creating an": "من خلال إنشاء حساب، يوافق المستخدمون على تلقي الاتصالات منا، بما في ذلك على سبيل المثال لا الحصر، الإشعارات والتحديثات والرسائل الترويجية المتعلقة بالحساب.",
                "Users manage": "يمكن للمستخدمين إدارة تفضيلات الاتصال الخاصة بهم من خلال إعدادات حساباتهم أو عن طريق الاتصال بنا.",
                "10. Compliance with Laws": "10. الامتثال للقوانين",
                "Users must comply": "يجب على المستخدمين الالتزام بجميع القوانين واللوائح المعمول بها عند استخدام حساباتهم والوصول إلى خدماتنا.",
                "11. User Contributions": " 11. مساهمات المستخدم",
                "Users should": "يجب على المستخدمين عدم انتهاك أي إرشادات عند إرسال الآراء أو التعليقات.",
                "Users not use": "يجب على المستخدمين عدم استخدام أي محتوى على الموقع لأغراض تجارية دون إذن.",
                "12. Dispute Resolution": " 12. حل النزاعات",
                "Governing": "القانون الحاكم: تخضع هذه الشروط والأحكام وأي نزاعات تنشأ عنها أو تتعلق بها لقوانين لبنان وتفسر وفقًا لها، بغض النظر عن تعارضها مع مبادئ القانون.",
                "Jurisdiction": "الاختصاص القضائي: أنت توافق على أن أي إجراء قانوني أو إجراء ينشأ عن أو يتعلق بهذه الشروط والأحكام أو استخدامك لموقعنا الإلكتروني/خدمتنا سيتم تقديمه حصريًا إلى محاكم لبنان. أنت توافق على الاختصاص القضائي لهذه المحاكم ومكان انعقادها وتتنازل عن أي اعتراض على هذا الاختصاص أو المكان.",
                "Informal": "الحل غير الرسمي: قبل الشروع في التحكيم، فإنك توافق على محاولة حل أي نزاع بشكل غير رسمي عن طريق الاتصال بنا على recibits@gmail.com. سنحاول حل النزاع بشكل غير رسمي عن طريق الاتصال بك عبر البريد الإلكتروني أو وسائل أخرى. إذا لم يتم حل النزاع خلال 30 يومًا من تقديمه، يجوز لأي من الطرفين البدء في إجراءات التحكيم.",
                "13. Compliance with GDPR and CCPA": "13. الامتثال للائحة العامة لحماية البيانات (GDPR) وCCPA",
                "GDPR": " الامتثال للائحة العامة لحماية البيانات (GDPR): إذا كنت مقيمًا في المنطقة الاقتصادية الأوروبية (EEA)، فلديك بعض حقوق حماية البيانات بموجب اللائحة العامة لحماية البيانات (GDPR). نحن ملتزمون بحماية بياناتك الشخصية وضمان معالجتها بشكل عادل وشفاف. لممارسة أي من هذه الحقوق، يرجى الاتصال بنا على recibits@gmail.com.",
                "CCPA": "الامتثال لقانون خصوصية المستهلك في كاليفورنيا (CCPA): إذا كنت مقيمًا في كاليفورنيا، فلديك حقوق معينة بموجب قانون خصوصية المستهلك في كاليفورنيا (CCPA). نحن ملتزمون بحماية بياناتك الشخصية وضمان معالجتها بشكل عادل وشفاف. لممارسة أي من هذه الحقوق، يرجى الاتصال بنا على recibits@gmail.com.",
                "14. Changes to These Terms": " 14. التغييرات على هذه الشروط",
                "We update terms": "يجوز لنا تحديث هذه الشروط من وقت لآخر. وسوف نقوم بإعلامك بأي تغييرات عن طريق نشر الشروط الجديدة على هذه الصفحة. يُنصح بمراجعة هذه الشروط بشكل دوري لمعرفة أي تغييرات.",
                "15. Contact US": "15. اتصل بنا",
                "If questions": "إذا كانت لديك أي أسئلة حول هذه الشروط، فيرجى الاتصال بنا على recibits@gmail.com."
        },
    },
    };
    i18n.use(initReactI18next).init({
        resources,

        lng: localStorage.getItem('language') || lang, // Default language
        keySeparator: false,
        interpolation: { escapeValue: false },
    });
    //#endregion
    
    //#region Functions
    const onLangClick = () => {
        if(lang == "en"){
        setLang("ar")
        Cookies.set('language', "ar", { expires: 365, path: '/' });
        }
        else{
        setLang("en")
        Cookies.set('language', "en", { expires: 365, path: '/' });
        } 
    }

    //#endregion

    //#region Use Effect
    useEffect(()=>{
        setLang(Cookies.get('language') || 'en')
    },[])
    //#endregion

return(
    
    <IonPage id="main-content">
      <IonHeader class='privacy_header'>
          <NavBar  lang={lang} onLangClick={onLangClick}></NavBar>
      </IonHeader>
      <div className='privacy_background'></div>
      <IonContent className="privacy_content">
        <div className='privacy_background_img'></div>
        <div className="privacy_story" style={lang=='en'?{direction: "ltr", textAlign: "left",marginLeft:"10px"}:{direction: "rtl", textAlign: "right",marginRight:"10px"}}>
            <h2 className="privacy_main_title">{t('Terms and Conditions')}</h2> 
            <h4 className="privacy_title">{t('1. Introduction:')}</h4>
            <p>{t('Welcome')}</p>
            <h4 className="privacy_title">{t('2. Eligibility')}</h4>
                <ul>
                    <li>{t('Users must be')}</li>
                    <li>{t('By creating')}</li>
                </ul>
            <h4 className="privacy_title">{t('3. Account Information')}</h4>
            <ul>
                <li>{t('Users must provide')}</li>
                <li>{t('Users are responsible')}</li>
            </ul>
            <h4 className="privacy_title">{t('4. Account Security')}</h4> 
            <ul>
                <li>{t('Users are responsible for')}</li>
                <li>{t('Users must notify')}</li>
                <li>{t('Users must use')}</li>
            </ul>
            <h4 className="privacy_title">{t('5. Account Usage')}</h4> 
            <ul>
                <li>{t('Users must not')}</li>
                <li>{t('Users are solely')}</li>
                <li>{t('Users must not use')}</li>
            </ul>
            <h4 className="privacy_title"> {t('6. Account Termination')} </h4> 
            <ul>
                <li>{t('We reserve')}</li>
                <li>{t('Users may')}</li>
            </ul>
            <h4 className="privacy_title"> {t('7. Multiple Accounts')}</h4> 
            <p>{t('Users are')}</p>
            <h4 className="privacy_title"> {t('8. Prohibited Activities')}</h4>
            <ul>
                <li>{t('Users engage')}</li>
                <li>{t('Users must respect')}</li>
            </ul>
            <h4 className="privacy_title"> {t('9. Notifications and Communication')}</h4>
            <ul>
                <li>{t('By creating an')}</li>
                <li>{t('Users manage')}</li>
            </ul>
            <h4 className="privacy_title"> {t('10. Compliance with Laws')}</h4>
            <p>{t('Users must comply')}</p>
            <h4 className="privacy_title"> {t('11. User Contributions')}</h4>
            <ul>
                <li>{t('Users should')}</li>
                <li>{t('Users not use')}</li>
            </ul>
            <h4 className="privacy_title"> {t('12. Dispute Resolution')}</h4>
            <ul>
                <li>{t('Governing')}</li>
                <li>{t('Jurisdiction')}</li>
                <li>{t('Informal')}</li>
            </ul>
            <h4 className="privacy_title"> {t('13. Compliance with GDPR and CCPA')}</h4>
            <ul>
                <li>{t('GDPR')}</li>
                <li>{t('CCPA')}</li>
            </ul>
            <h4 className="privacy_title">{t('14. Changes to These Terms')}</h4>
            <p>{t('We update Terms')}</p>
            <h4 className="privacy_title">{t('15. Contact US')}</h4>
            <p>{t('If questions')}</p>
        </div>
            
            <Footer lang={lang}></Footer>

            </IonContent>
   
      </IonPage>

)

}

export default Terms;