import { IonHeader, IonTitle, IonItem, IonList, IonToolbar, IonContent, IonMenu} from "@ionic/react";
import { menuController } from '@ionic/core/components';
import {searchOutline, person} from 'ionicons/icons';
import './MenuBar.css';
import logo from '../resources/logo/logo_transparent_edited.png';
import "@fontsource/abril-fatface";
import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';

//MultiLanguage
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { t } from 'i18next';

const MenuBar = (props) => {

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const history = useHistory();

    //Language Data
    const resources = {
      en: {
          translation: {
            "Home": "Home",
            "About Us": "About Us",
            "Contact": "Contact",
          },
      },
      ar: {
          translation: {
            "Home": "الصفحة الرئيسية",
            "About Us": "معلومات عنا",
            "Contact": "اتصال",
          },
      },
    };
  
    i18n.use(initReactI18next).init({
      resources,
      lng: localStorage.getItem('language') || props.lang, // Default language
      keySeparator: false,
      interpolation: { escapeValue: false },
    });

  const onClickNav = (name) => {

    switch (name) {
      case 'home':
        menuController.close()
        window.history.replaceState(null, null, '/');
        window.location.reload();
        break;
      case 'aboutus':
        menuController.close()
        history.push({
          pathname: '/home/aboutus'
        })
        break;
      case 'contact':
        menuController.close()
        history.push({
          pathname: '/home/contact'
        })
      default:
        break;
    }

  }



   return(
    <IonMenu menuId="first-menu" contentId="main-content">
      <IonHeader className='menu_header'>
          <IonToolbar className="menu_toolbar" style={{ backgroundColor: '#196b69' }}>
            <IonTitle style={{ color: 'white', fontWeight: 'bold' }}>Recibits Menu</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="menu_content" style={{ backgroundColor: '#114b4a', color: 'white' }}>
          <IonList   className="menu_list">
            <IonItem className="menu_item" button onClick={()=>{onClickNav('home')}}>
              {t('Home')}
            </IonItem>
            <IonItem className="menu_item" button onClick={()=>{onClickNav('aboutus')}}>
              {t('About Us')}
            </IonItem>
            <IonItem className="menu_item" button onClick={()=>{onClickNav('contact')}}>
              {t('Contact')}
            </IonItem>
          </IonList>
          <div className="menu_img_div">
            <img src={logo} className="menu_img"></img>
          </div>
          
        </IonContent>
      </IonMenu>
   )
    

}

export default MenuBar;