//#region Imports
//Import Ionic Components
import { IonContent, IonHeader, IonPage } from "@ionic/react"
import { menuController } from '@ionic/core/components';
//Import Components
import NavBar from "../components/NavBar";
import Footer from '../components/Footer';
import MenuBar from "../components/MenuBar";
//Import Style
import './AboutUs.css';
//MultiLanguage
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { t } from 'i18next';
//Import React Components
import { useState, useEffect } from 'react';
//Import JS Cookies
import Cookies from 'js-cookie';
//#endregion

const AboutUs = () => {

  //#region Variables
  //Language Data
  const [lang, setLang] = useState("en")
  const resources = {
      en: {
          translation: {
              "About Recibits": "About Recibits",
              "Our Story": "Our Story",
              "Living": "Living abroad and away from family, we found ourselves relying on fast food and unhealthy options. However, we soon realized that this lifestyle wasn't sustainable or satisfying.",
              "Determined": "Determined to make a change, we started the journey of cooking worldwide meals from scratch. It was a journey of ups and downs, laughter and frustrations, but most importantly, it was an adventure that brought us closer together and ignited a passion for cooking.",
              "As": "As we honed our skills and discovered the joy of creating meals, we decided to share the experience with everyone. And so, Recibits was born - a place where we could document our adventures, share our favorite recipes, and inspire others to start their there own cooking journey.",
              "Our Mission": "Our Mission",
              "We aim": "We aim to empower home cooks of all skill levels to improve their creativity and discover the joy of cooking. We strive to create a diverse collection of recipes, cooking tips and techniques that inspire our community.",
              "What Sets Us Apart": "What Sets Us Apart",
              "What makes": "What makes Recibits different is our commitment to making cooking accessible and enjoyable for everyone. Whether you are professional chef or a kitchen novice, our recipes are designed to be approachable and practical.",
              "Join Our Community": "Join Our Community",
              "We invite": "We invite you to join our growing community of food enthusiasts! Connect with us on social media and drop us your opinion. We'd love to hear from you!"
          },
      },
      ar: {
          translation: {
              "About Recibits": "من نحن",
              "Our Story": "قصتنا",
              "Living" : "بسبب العيش في الخارج وبعيدًا عن العائلة، وجدنا أنفسنا نعتمد على الوجبات السريعة والخيارات غير الصحية. ومع ذلك، سرعان ما أدركنا أن نمط الحياة هذا لم يكن مستدامًا أو مُرضيًا",
              "Determined": "عاقدين العزم على إحداث التغيير، بدأنا رحلة طهي الوجبات العالمية من الصفر. لقد كانت رحلة مليئة بالصعود والهبوط، والضحك والإحباط، ولكن الأهم من ذلك أنها كانت مغامرة جعلتنا أقرب لبعضنا البعض وأشعلت شغفنا بالطهي",
              "As": "وبينما صقلنا مهاراتنا واكتشفنا متعة تحضير الوجبات، قررنا مشاركة التجربة مع الجميع. وهكذا وُلدت Recibits - مكان يمكننا من خلاله توثيق مغامراتنا ومشاركة وصفاتنا المفضلة وإلهام الآخرين لبدء رحلة الطهي الخاصة بهم",
              "Our Mission": "مهمتنا",
              "We aim": "نحن نهدف إلى تمكين الطهاة المنزليين من جميع مستويات المهارة لتحسين قدراتهم الإبداعية واكتشاف متعة الطهي. نحن نسعى جاهدين لإنشاء مجموعة متنوعة من الوصفات ونصائح الطبخ والتقنيات التي تلهم مجتمعنا",
              "What Sets Us Apart": "ماذا يميزنا",
              "What makes": "ما يجعل Recibits مختلفًا هو التزامنا بجعل الطبخ متاحًا وممتعًا للجميع. سواء كنت طاهيًا محترفًا أو مبتدئًا في المطبخ، فقد تم تصميم وصفاتنا لتكون ودودة وعملية",
              "Join Our Community": "انضم لمجموعتنا",
              "We invite": "نحن ندعوك للانضمام إلى مجتمعنا المتنامي من عشاق الطعام! تواصل معنا عبر وسائل التواصل الاجتماعي وشاركنا رأيك. نحن نحب أن نسمع منك"
    },
  },
  };
  i18n.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem('language') || lang, // Default language
    keySeparator: false,
    interpolation: { escapeValue: false },
  });
  //#endregion

  //#region Functions
  const onLangClick = () => {
    if(lang == "en"){
      setLang("ar")
      Cookies.set('language', "ar", { expires: 365, path: '/' });
    }
    else{
      setLang("en")
      Cookies.set('language', "en", { expires: 365, path: '/' });
    } 
  }
  //#endregion

  //#region Use Effect
  useEffect(()=>{
    setLang(Cookies.get('language') || 'en')
  },[])
  //#endregion


return(

    <IonPage id="main-content">
        <IonHeader class='aboutus_header'>  
          <NavBar  lang={lang} onLangClick={onLangClick}></NavBar>
        </IonHeader>
        <div className='aboutus_background'></div>
        <IonContent className="aboutus_content">
          <div className='aboutus_background_img'></div>
          <div className="aboutus" style={lang=='en'?{direction: "ltr", textAlign: "left"}:{direction: "rtl", textAlign: "right"}}>
              <h2 className="aboutus_mainTitle">{t('About Recibits')}</h2> <br></br> <br></br>
              <div className="aboutus_body_div">
                  <h1 className="aboutus_titles">{t('Our Story')}</h1>
                  <h5>{t('Living')}</h5>
                  <h5>{t('Determined')}</h5>
                  <h5>{t('As')}</h5><br></br>
                  <h3 className="aboutus_titles">{t('Our Mission')}</h3>
                  <h5>{t('We aim')}</h5><br></br>
                  <h3 className="aboutus_titles">{t('What Sets Us Apart')}</h3>
                  <h5>{t('What makes')}</h5><br></br>
                  <h3 className="aboutus_titles">{t('Join Our Community')}</h3>
                  <h5>{t('We invite')}</h5><br></br>
              </div>
          </div>         
          <Footer lang={lang}></Footer>
        </IonContent>
    </IonPage>

)

}

export default AboutUs;