import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonImg, IonIcon } from '@ionic/react';
import './RecipeCard.css';
import pic from '../resources/recipes/chorizo-mozarella-gnocchi-bake-cropped.jpg'
import plate1 from '../resources/recipes/rice.png'
import plate2 from '../resources/special.png'
import crown from '../resources/crown.png'
import { peopleOutline, speedometerOutline, timeOutline, lockClosed } from 'ionicons/icons';
// import imgg from 'drive.google.com/uc?export=view&id=1n12_Cy3nfISJNn-5pWIdJU_ahhhJNyKQ'

const RecipeCard = (props) => {

    // console.log(recipe.recipe.state);
    if(props.recipe.state.images != null){
      // console.log(props.language)
      // console.log(recipe.recipe.state.images[0])
    }

    // const img = require("drive.google.com/uc?export=view&id=1n12_Cy3nfISJNn-5pWIdJU_ahhhJNyKQ")
    return(


      <div className={props.category!='Drinks' ? 'recipe_card recipe_card_rot' : 'recipe_card'} style={props.recipe.state.isPremium? {}:{}} onClick={()=>{props.onClickRecipe(props.recipe.getId())}}>
        {props.recipe.state.isPremium?
          <div className="recipe_image_container">
          <img src={crown}></img>
          </div>:null
        }
        { props.recipe.state.images==null ?(
          <img className='recipe_card_image' src={plate2}></img>
        ):(
          <img className='recipe_card_image' src={props.recipe.state.images[0]}></img>
         )}
        {/* <img className='recipe_card_image' src={plate1}></img> */}

        <div  className={props.recipe.state.isPremium?'recipe_card_header recipe_card_header_premium' :'recipe_card_header'}>
          {props.language == 'en' ? props.recipe.getTitle() : props.recipe.getTitleAr()}
          {props.recipe.state.isPremium?<div style={{fontWeight:'lighter', marginTop:'5px', color:'goldenrod'}}>{props.language=='en' ? 'Premium' : 'مميزة'}</div>:null}
          
        </div>

        <div className='recipe_card_footer'>
          <div className='footer_btn_left'>
            <IonIcon className='footer_icon' icon={timeOutline}/>
            <div className='footer_text'>{props.recipe.getTime() ?  (props.language=='en' ? props.recipe.getTime() : props.recipe.getTimeAr()): '-'}</div>
          </div>
          <div className='footer_btn_middle'>
            <IonIcon className='footer_icon' icon={peopleOutline}/>
            <div className='footer_text'>{props.recipe.getPortion() ? (props.language=='en' ? props.recipe.getPortion() : props.recipe.getPortionAr()): '-'}</div>
          </div>
          <div className='footer_btn_right'>
            <IonIcon className='footer_icon' icon={speedometerOutline}/>
            <div className='footer_text'>{props.recipe.getDifficulty() ? (props.language=='en' ? props.recipe.getDifficulty() : props.recipe.getDifficultyAr()): '-'}</div>
          </div>
        </div>

      </div>


    )
    
}

export default RecipeCard;